/**
 * @param {Array} sources array of sources
 */
export function emitSound(sources) {
	const audio = document.createElement('audio');

	for (const source of sources) {
		const sourceElement = document.createElement('source');
		const sourceType = source.split('.').pop();
		sourceElement.src = source;
		sourceElement.type = 'audio/' + sourceType;

		audio.appendChild(sourceElement);
	}

	audio.play();
}

/**
 *
 * @param {String} source audio source
 */
export function playSingleSound(source) {
	new Audio(source).play();
}
