import emitSoundMixin from '@/mixin/emitSound';

const CREATIVE_SCREEN_NAME = 'クリエイティブ';

export default {
	name: 'BottomButton',
	mixins: [emitSoundMixin],
	props: {
		buttonName: {
			type: String,
		},
		screenName: {
			type: String,
		},
		/**
		 * @binding Button Type
		 * @value {String} single-btn
		 * @value {String} btn-with-text
		 * @value {String} select-able-btn
		 * @value {String} direct-screen-btn
		 */
		buttonType: {
			type: String,
		},
		declineButtonName: {
			type: String,
		},
		acceptButtonName: {
			type: String,
		},
		directScreenButtonName: {
			type: String,
		},
		denyButtonName: {
			type: String,
		},
		directToSettlement: {
			type: Boolean,
		},
		bottomButtonCaption: {
			type: String,
			default: '承認依頼が届いています',
		},
		bottomButtonDirectCaption: {
			type: String,
			default: 'が保存されました',
		},
	},

	methods: {
		onBottomButtonClick(event) {
			this.$emit('on-confirm-button-trigger', event);
		},
		onAcceptClick(event) {
			this.$emit('on-accept-button-trigger', event);
		},
		onDeclineClick(event) {
			this.$emit('on-decline-button-trigger', event);
		},
		onDirectClick(event) {
			if (this.screenName !== CREATIVE_SCREEN_NAME) {
				this.emitMixinProcessingSound({ isSocketIncluded: true });
			}
			this.$emit('on-direct-button-trigger', event);
		},
	},
};
