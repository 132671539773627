import { checkMobileScreen } from '@/store/modules/ScreenWidth';

const STATUS_LIST = [
	// add public route 27/7/2023 HoaDQ
	{ text: 'スケジュール', value: 'schedule' },
	{ text: 'キックオフミーティング', value: 'hearing' },
	// { text: '見積書作成', value: 'estimate' },
	{ text: 'コンセプトデザイン', value: 'decision' },
	// { text: 'クリエイティブ', value: 'creative' },
	// { text: 'ご提案', value: 'proposal' },
	// { text: '再提案設定', value: 'addcost' },
	// { text: '再提案', value: 'reproposal' },
	{ text: 'コンセプト仕上げ', value: 'delivery' },
	{ text: 'デジタル認証', value: 'nft' },
	{ text: '完了', value: 'finish' },
	{ text: '保留', value: '' },
];

const FREEZE_STATUS = 5;

const NFT_DESCRIPTION = checkMobileScreen()
	? '偽造・改ざんを防ぐ技術で「唯一無二の作品である」\nことを証明しデジタル資産価値を高めることが出来ます。'
	: '偽造・改ざんを防ぐ技術で「唯一無二の作品である」ことを証明し\nデジタル資産価値を高めることが出来ます。';

export default {
	STATUS_LIST,
	FREEZE_STATUS,
	NFT_DESCRIPTION,
};
