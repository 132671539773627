import axiosBase from 'axios';

const apiUrl = {
	mail: '/api/line/',
};

const axios = axiosBase.create({
	// This is development host
	baseURL: process.env.VUE_APP_BASE_URL,
});

axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
};

class Line {
	/**
	 *
	 * @param {Object} data
	 * @returns {Object}
	 */
	static sendLine = async (data) => {
		try {
			let response = await axios.post(apiUrl.mail, {
				message: data.message,
				user_id: data.userId,
			});
			return response;
		} catch (err) {
			return err;
		}
	};
}
export default Line;
