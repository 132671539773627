import ApiService from './api.service';

const serviceURL = 'decision';
const DecisionService = {
	/**
	 * Acquire decision and children by projectID
	 * @param {Integer} projectId
	 * @returns {Object} decision and children
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}/children?withParent=true`);
	},

	/**
	 * Create new Decision record
	 * @param {Object} body {doc_content: {decision Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update Decision record
	 * @param {Integer} projectId Project ID
	 * @param {Object} body {doc_content: {decision Object}}
	 * @returns {Object} success message
	 */
	update(projectId, body) {
		return ApiService.put(`${serviceURL}/${projectId}`, body);
	},

	/**
	 * confirm Decision content
	 * @param {Integer} projectId: Project ID
	 * @returns success message
	 */
	confirm(projectId) {
		return ApiService.put(`${serviceURL}/${projectId}/confirm`);
	},
};

export default DecisionService;
