import ApiService from './api.service';

const serviceURL = 'project';
const ProjectService = {
	/**
	 * Acquire project by projectID
	 * @param {Integer} projectId
	 * @returns {Object} project details
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},

	/**
	 * Get project by query string
	 * @param {Integer} param query object
	 * @returns {Object} project object
	 */
	search(param) {
		return ApiService.query(`${serviceURL}/search`, param);
	},

	/**
	 * Update project content from text box
	 * @param {Integer} projectId: Project ID
	 * @param {Object} contents: Project contents for updating
	 * @returns
	 */
	update(projectId, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/${projectId}`, body);
	},

	/**
	 * confirm project content
	 * @param {Integer} projectId: Project ID
	 * @returns
	 */
	confirm(projectId) {
		return ApiService.put(`${serviceURL}/${projectId}/confirm`, {});
	},

	/**
	 * Update status based in processing screen idx
	 * @param {Integer} projectId
	 * @param {Integer} value  processing screen idx
	 * @returns
	 */
	update_status(projectId, value) {
		return ApiService.put(`${serviceURL}/${projectId}/status?value=${value}`);
	},

	// /**
	//  * Update user start's status based in processing screen idx
	//  * @param {Integer} projectId
	//  * @param {Object} content  processing screen idx
	//  * @returns
	//  */
	// update_start_status(projectId, content) {
	// 	return ApiService.put(
	// 		`${serviceURL}/${projectId}/${content.key}?value=${content.value}`
	// 	);
	// },
};

export default ProjectService;
