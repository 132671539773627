import Mail from '@/services/API/mail.service';
import Line from '@/services/API/line.service';

const MAIL_METHOD = 0;
const LINE_METHOD = 1;
const MAIL_AND_LINE_METHOD = 2;
/**
 *
 * @param {Object or string} content
 * @param {Int} type
 * @param {Array} userIds
 *
 */

export const sendNotify = async (...args) => {
	try {
		let [content, type, userIds] = args;
		console.log('%c Send notify', 'color: blue', args);
		switch (type) {
			case MAIL_METHOD:
				await sendMail(content);
				break;
			case LINE_METHOD:
				await sendLine(content, userIds);
				break;
			case MAIL_AND_LINE_METHOD:
				await sendMail(content.mailContent);
				await sendLine(content.lineContent, userIds);
				break;
			default:
				throw 'Invalid notify type!';
		}
	} catch (error) {
		console.log('%c Send Fail!', 'color: red');
	}
};

const sendMail = async (content) => {
	let responseSendMail = await Mail.sendMail(content);
	if (responseSendMail.status !== 200) {
		throw 'Send mail failed!';
	}
	console.log('%c Send mail successfully!', 'color: green');
};
const sendLine = async (content, userIds) => {
	userIds.forEach(async (id) => {
		let responseSendLine = await Line.sendLine({
			message: content,
			userId: id,
		});
		if (responseSendLine.status !== 200) {
			console.log('%c Send line failed!', 'color: red');
		}
	});
};

export const toLineMessageTemplate = ({
	title = '',
	message = '',
	currentUrl = '',
}) => {
	return (
		`${title}\n` + `\n` + `${message}\n` + `\n` + `▼CoMoDeを開く\n` + `${currentUrl}\n`
	);
};
