const MIME_TYPE_REGEX = new RegExp(
	/(pdf|mp3|mp4|zip|sheet|postscript|x-7z-compressed|msword|wordprocessingml|excel|presentation|powerpoint|vnd.adobe.photoshop|x-photoshop|psd)/g
);
const POSTSCRIPT_FILES = ['ps', 'eps', 'ai'];
module.exports = {
	isImage: function (fileType) {
		let notImageType = ['vnd.adobe.photoshop', 'x-photoshop', 'psd'];
		let [type, subType] = fileType.split('/');
		return type == 'image' && notImageType.indexOf(subType) == -1;
	},
	/**
	 *
	 * @param {String} fileType file type
	 * @param {String} FileName file name
	 * @param {Boolean} filled determine icon is filled background or not
	 * @returns
	 */
	getIcon: function (fileType, FileName, filled) {
		// Common MIME types
		// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
		const extraFilled = filled ? '_filled' : '';
		let [type, subType] = fileType.split('/');
		let fileFormat = subType ? subType.match(MIME_TYPE_REGEX) : false;
		let extension = FileName ? FileName.split('.').pop() : '';
		switch (fileFormat ? fileFormat[0] : type) {
			case 'pdf':
				return require(`@/assets/FileIcon/20220922/icon-pdf${extraFilled}.svg`);
			case 'mp3':
				return require(`@/assets/FileIcon/20220922/icon-mp3${extraFilled}.svg`);
			case 'mp4':
				return require(`@/assets/FileIcon/20220922/icon-mp4${extraFilled}.svg`);
			case 'zip':
				return require(`@/assets/FileIcon/20220922/icon-zip${extraFilled}.svg`);
			case 'msword':
			case 'wordprocessingml':
				return require(`@/assets/FileIcon/20220922/icon-word${extraFilled}.svg`);
			case 'sheet':
			case 'excel':
				return require(`@/assets/FileIcon/20220922/icon-excel${extraFilled}.svg`);
			case 'postscript':
				if (POSTSCRIPT_FILES.indexOf(extension) == -1) {
					return require(`@/assets/FileIcon/20220922/icon-unknow${extraFilled}.svg`);
				}
				return require(`@/assets/FileIcon/20220922/icon-${extension}${extraFilled}.svg`);
			case 'x-7z-compressed':
				return require(`@/assets/FileIcon/20220922/icon-7zip${extraFilled}.svg`);
			case 'vnd.adobe.photoshop':
			case 'x-photoshop':
			case 'psd':
				return require(`@/assets/FileIcon/20220922/icon-psd${extraFilled}.svg`);
			case 'svg+xml':
				return require(`@/assets/FileIcon/20220922/icon-svg${extraFilled}.svg`);
			case 'powerpoint':
			case 'presentation':
				return require('@/assets/FileIcon/20220922/icon-powerpoint.svg');
			default:
				return require(`@/assets/FileIcon/20220922/icon-unknow${extraFilled}.svg`);
		}
	},
	downloadFile: function (url) {
		console.log('url', url);
		window.open(url);
	},
	getPieChart(status) {
		if (status < 2) return [false, ''];
		if (status >= 9) return [false, ''];
		if (status === 2)
			return [true, require('../../assets/PieChart/pie-chart-' + status + '.svg')];
		if (status === 3) {
			status += 1;
			return [true, require('../../assets/PieChart/pie-chart-' + status + '.svg')];
		}
		if (status === 4) {
			status += 2;
			return [true, require('../../assets/PieChart/pie-chart-' + status + '.svg')];
		}
		if (status === 5) {
			status += 3;
			return [true, require('../../assets/PieChart/pie-chart-' + status + '.svg')];
		}
	},
};
