import axiosBase from 'axios';

const apiUrl = {
	mail: '/api/mail/',
};

const axios = axiosBase.create({
	// This is development host
	baseURL: process.env.VUE_APP_BASE_URL,
});

axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
};

class Mail {
	/**
	 *
	 * @param {Object} data
	 * @returns {Object}
	 */
	static sendMail = async (data) => {
		try {
			let response = await axios.post(apiUrl.mail, data);
			return response;
		} catch (err) {
			return err;
		}
	};
}
export default Mail;
