const TOTAL_STARS = 5;
const STAR_POSITION = 1;
const MIN_WIDTH = 20;

export default {
	name: 'StarRating',
	props: {
		rating: Number,
		disabled: Boolean,
	},
	watch: {
		rating: {
			handler(newRating) {
				console.log({ 'current rating': newRating });
				this.ratingStar = newRating;
			},
		},
	},
	data() {
		return {
			totalStars: TOTAL_STARS,
			currentRatingWidth: 0,
			ratingStar: 0,
		};
	},
	methods: {
		onRate(index, { isManualRating }) {
			if (this.disabled) return;
			
			this.ratingStar = index + STAR_POSITION;
			this.currentRatingWidth = (index + STAR_POSITION) * MIN_WIDTH;

			if (isManualRating) this.$emit('update-rating', this.ratingStar);
		},
		findIndexFromStar(ratingStar) {
			return ratingStar - STAR_POSITION;
		},
	},
	mounted() {
		console.log({ 'current rating': this.rating });
		this.onRate(this.findIndexFromStar(this.rating), { isManualRating: false });
	},
};
