import { getIcon } from '../store/modules/Image';

const SCREEN_STATUS = {
	WAITING_CONFIRM: 'onWaitingConfirm',
	ACCEPT_CONFIRM: 'onAcceptConfirm',
	ACCEPT: 'onAccept',
	DENY_CONFIRM: 'onDenyConfirm',
	FINISH: 'onFinished',
	SAVE: 'onSave',
	SEND: 'onSend',
	TIME_OUT: 'onTimeOut',
	COUNTING_DOWN: 'onCountingDown',
	MENU_OPEN: 'onMenuOpen',
	REVISION: 'onRevision',
	DECLINE: 'onDecline',
	PAID: 'onPaid',
	WAITING_PAYMENT: 'onWaitingPayment',
};

const ROUTE_NAME = {
	FINISH: 'finish',
};

const PDF_FILE_ICON = getIcon('pdf', '', true);
const CREATOR_AVATAR_URL = '/static/img/CreatorAvatar.a6f8291b.png';
const CLIENT_AVATAR_URL = '/static/img/ClientAvatar.ba79f4dd.png';

const PROPOSAL_MODE_NUMB = 0;
const REVISION_MODE_NUMB = 1;
const PROPOSAL_SCREEN_NAME = 'ご提案';
const REVISION_SCREEN_NAME = 'ご提案（修正）';
const RE_REVISION_SCREEN_NAME = '再提案（修正）';
const RE_PROPOSAL_SCREEN_NAME = '再提案';

const CONFIRMED = 1;
const LIVE_SCREEN_INDEX = 0;
const CO_CREATE_INDEX = 3;
const SETTLEMENT_INDEX = 6;
const SHARE_SCREEN_INDEX = 1;
const ONE_WEEK_SECONDS = 604800;
const MOBILE_SCREEN_MAX_WIDTH = 992;
const MANAGEMENT_MASTER_ID = 1; //temporary managementMasterID, will get from API in the future
const EMOJI_SIZE = 20;

const MODAL_CONTENTS = {
	CONFIRM_DELETE: '削除してもよろしいですか？',
	CONFIRM_SKIP: '提案時間をスキップしますか？',
};

const SCREEN_ID = {
	SCHEDULE: 0,
	HEARING: 1,
	// ESTIMATE: 2,
	DECISION: 2,
	// CREATIVE: 4,
	// PROPOSAL: 5,
	// ADDCOST: 6,
	// REPROPOSAL: 7,
	DELIVERY: 3,
	NFT: 4,
	SETTLEMENT: 5,
};

const AUTHORITY = {
	CREATOR: 1,
	ADMIN: 2,
	CLIENT: 3,
};

const COCREATE_SOCKET_INDEX = {
	KEYWORD_DROPDOWN: 0,
	KEYWORD_LIST: 1,
	CONTENT: 2,
	IMG_LIST: 3,
	CLEAR_CONTENT: 4,
	IMG_RESULT: 5,
};

export default {
	ONE_WEEK_SECONDS,
	SCREEN_STATUS,
	SCREEN_ID,
	MODAL_CONTENTS,
	MOBILE_SCREEN_MAX_WIDTH,
	MANAGEMENT_MASTER_ID,
	CREATOR_AVATAR_URL,
	CLIENT_AVATAR_URL,
	PROPOSAL_MODE_NUMB,
	REVISION_MODE_NUMB,
	REVISION_SCREEN_NAME,
	PROPOSAL_SCREEN_NAME,
	RE_REVISION_SCREEN_NAME,
	RE_PROPOSAL_SCREEN_NAME,
	CONFIRMED,
	LIVE_SCREEN_INDEX,
	CO_CREATE_INDEX,
	SHARE_SCREEN_INDEX,
	AUTHORITY,
	PDF_FILE_ICON,
	COCREATE_SOCKET_INDEX,
	EMOJI_SIZE,
	ROUTE_NAME,
	SETTLEMENT_INDEX,
};
