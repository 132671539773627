import socketClient from '@/services/SOCKET';
import { playSingleSound } from '@/store/modules/Sound';

import { mapState } from 'vuex';

export default {
	computed: {
		...mapState(['isSoundEnabled']),
	},
	methods: {
		emitMixinStartingSound() {
			if (this.isSoundEnabled) {
				playSingleSound(require('../assets/sounds/start.wav'));
			}
		},

		emitMixinProcessingSound({ isSocketIncluded }) {
			if (this.isSoundEnabled) {
				playSingleSound(require('../assets/sounds/process.wav'));
			}

			if (isSocketIncluded) {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.$store.state.userId,
						event_name: 'emit_processing_sound',
					},
					room: this.$store.state.projectId,
				});
			}
		},

		emitMixinFinishingSound(isSocketIncluded) {
			if (this.isSoundEnabled) {
				playSingleSound(require('../assets/sounds/finish.wav'));
			}

			if (isSocketIncluded) {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.$store.state.userId,
						event_name: 'emit_finishing_sound',
					},
					room: this.$store.state.projectId,
				});
			}
		},
	},
};
